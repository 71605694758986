'use client';
import { GavelIconOutline } from '@c/banners/AuctionBanner';
import Boxes from '@c/icons/Boxes';
import { RightAngularBracket } from '@c/icons/RightAngularBracket';
import ShopMenu from '@c/menus/ShopMenu';
import Button from '@ui/Button';
import { useNotifications } from '@util/hooks/useNotifcations';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FaBicycle, FaMoneyBillWave } from 'react-icons/fa';
import {
  BalancesIcon,
  BoxIcon,
  CartIcon,
  CurationsIcon,
  EmptyWalletIcon,
  FollowingIcons,
  GarageIcon,
  HandIcon,
  HeartIcon,
  ListOutlineIcon,
  LocationIcon,
  LogoutIcon,
  PersonOutlineIcon,
  PlaneIcon,
  PrintALabelIcon,
  RotateIcon,
  SMSIcon,
  SettingsIcon,
  ShootingStarIcon,
  StarOutlineIcon,
  StorefrontIcon,
} from '../icons';
import ShopBySportMenu from '@c/menus/ShopBySportMenu';
import { useState } from 'react';

const DashboardMenuItem = ({
  text,
  icon,
  selected,
  href,
  menuItemClicked,
  badgeCount,
}: {
  text: string;
  icon: React.ReactNode;
  href: string;
  badgeCount?: number;
  selected?: boolean;
  menuItemClicked: () => void;
}) => (
  <Link
    href={href}
    className={`flex h-[5rem] flex-col justify-center transition-colors hover:bg-brand-secondary hover:text-white ${
      selected ? 'bg-brand-secondary text-white' : ''
    }  mx-[1.6rem] rounded-[0.6rem]`}
    onClick={() => {
      menuItemClicked();
    }}
  >
    <div className="flex items-center justify-between p-[1rem]">
      <div className="flex items-center gap-[1.6rem]">
        <div className={`text-inherit`}>{icon}</div>
        <p className="text-[1.6rem] font-normal hover:text-brand-white">
          {text}
        </p>
        {!!badgeCount && badgeCount > 0 && (
          <div className="relative">
            {/* ring around the badge for pulse affect */}
            <div
              className={`flex  aspect-square h-[2rem] items-center justify-center rounded-full ${
                selected
                  ? 'bg-white text-brand-secondary'
                  : 'bg-brand-secondary text-white'
              } absolute animate-ping text-[1.2rem] font-medium`}
            />
            <div
              className={`flex aspect-square h-[2rem] items-center justify-center rounded-full ${
                selected
                  ? 'bg-white text-brand-secondary'
                  : 'bg-brand-secondary text-white'
              } text-[1.2rem] font-medium`}
            >
              {badgeCount}
            </div>
          </div>
        )}
      </div>

      {selected ? (
        <div className=" text-brand-white">
          <RightAngularBracket />
        </div>
      ) : null}
    </div>
  </Link>
);

const DashboardSideMenu = ({
  menuItemClicked,
}: {
  menuItemClicked: () => void;
}) => {
  const { logout, userDoc } = useAuth();
  const pathname = usePathname() ?? '';

  const [activeCategory, setActiveCategory] = useState<string>('');

  const {
    unreadCount,
    expertUnreadCount,
    pendingOrdersCount,
    pendingOffersCount,
    pendingReviewsCount,
    requiresStripeDetails,
    integrationRequestsCount,
  } = useNotifications();

  const onClickCateory = (category: string) => {
    setActiveCategory(category);
  };

  if (activeCategory === '') {
    return (
      <div className="flex h-full min-w-[26rem] flex-col gap-[0.4rem] overflow-y-scroll bg-brand-white pb-[15rem] lg:overflow-y-auto ">
        {/* <ProfileItem /> */}
        <div className="pb-8 pt-4 lg:hidden lg:pb-0 lg:pt-0">
          <ShopBySportMenu
            onClick={menuItemClicked}
            activeCategory={activeCategory}
            onClickCategory={onClickCateory}
          />
        </div>

        {!!userDoc && (
          <>
            <div className="flex flex-col p-[1.2rem]">
              <p className=" font-normal text-black">ACCOUNT</p>
            </div>

            {userDoc?.roles?.admin && (
              <>
                <DashboardMenuItem
                  menuItemClicked={menuItemClicked}
                  href="/admin"
                  text="Admin"
                  icon={<SettingsIcon />}
                />
              </>
            )}

            {userDoc?.roles?.support && (
              <>
                <DashboardMenuItem
                  menuItemClicked={menuItemClicked}
                  href="/support"
                  text="Support"
                  icon={<SettingsIcon />}
                />
              </>
            )}

            {userDoc?.roles?.curated_lists || userDoc?.roles?.admin ? (
              <DashboardMenuItem
                menuItemClicked={menuItemClicked}
                href="/dashboard/curated-lists"
                text="Curated Lists"
                icon={<SettingsIcon />}
              />
            ) : null}

            {userDoc?.roles?.marketing || userDoc?.roles?.admin ? (
              <DashboardMenuItem
                menuItemClicked={menuItemClicked}
                href="/dashboard/banners"
                text="Banners"
                icon={<SettingsIcon />}
              />
            ) : null}

            {userDoc?.roles?.seo || userDoc?.roles?.admin ? (
              <>
                <DashboardMenuItem
                  menuItemClicked={menuItemClicked}
                  href="/dashboard/categories"
                  text="Categories"
                  icon={<SettingsIcon />}
                />
                <DashboardMenuItem
                  menuItemClicked={menuItemClicked}
                  href="/dashboard/brands"
                  text="Brands"
                  icon={<SettingsIcon />}
                />
              </>
            ) : null}

            <DashboardMenuItem
              selected={pathname.includes('messages')}
              menuItemClicked={menuItemClicked}
              text="Messages"
              icon={<SMSIcon />}
              badgeCount={unreadCount}
              href="/dashboard/messages"
            />

            <DashboardMenuItem
              selected={pathname.includes('offers')}
              menuItemClicked={menuItemClicked}
              text="Offers"
              icon={<HandIcon />}
              badgeCount={pendingOffersCount ?? 0}
              href="/dashboard/offers"
            />

            {userDoc?.roles?.expert || userDoc?.roles?.admin ? (
              <DashboardMenuItem
                menuItemClicked={menuItemClicked}
                href="/dashboard/experts"
                text="Experts"
                badgeCount={expertUnreadCount}
                icon={<CurationsIcon />}
              />
            ) : null}

            <DashboardMenuItem
              selected={pathname.includes('profile')}
              menuItemClicked={menuItemClicked}
              href="/dashboard/my-profile"
              text="Profile"
              icon={<PersonOutlineIcon />}
            />

            <DashboardMenuItem
              selected={pathname.includes('my-garage')}
              menuItemClicked={menuItemClicked}
              text="My Garage"
              icon={<GarageIcon />}
              href="/dashboard/my-garage"
            />

            <DashboardMenuItem
              selected={pathname.includes('purchases')}
              menuItemClicked={menuItemClicked}
              text="Purchases"
              icon={<CartIcon />}
              href="/dashboard/purchases"
            />

            <DashboardMenuItem
              selected={pathname.includes('favorites')}
              menuItemClicked={menuItemClicked}
              text="Favorites"
              icon={<HeartIcon />}
              href="/dashboard/favorites"
            />

            <DashboardMenuItem
              selected={pathname.includes('following')}
              menuItemClicked={menuItemClicked}
              text="Following"
              icon={<FollowingIcons />}
              href="/dashboard/following"
            />

            <DashboardMenuItem
              selected={pathname.includes('addresses')}
              menuItemClicked={menuItemClicked}
              text="Addresses"
              icon={<LocationIcon />}
              href="/dashboard/addresses"
            />

            <DashboardMenuItem
              selected={pathname.includes('payments')}
              menuItemClicked={menuItemClicked}
              text="Payments"
              icon={<EmptyWalletIcon />}
              href="/dashboard/payments"
            />

            <DashboardMenuItem
              selected={pathname.includes('bids')}
              menuItemClicked={menuItemClicked}
              text="Bids"
              icon={<GavelIconOutline />}
              href="/dashboard/bids"
            />

            <DashboardMenuItem
              selected={pathname.includes('reviews')}
              menuItemClicked={menuItemClicked}
              text="Reviews"
              icon={<ShootingStarIcon />}
              badgeCount={pendingReviewsCount ?? 0}
              href="/dashboard/reviews"
            />

            {(userDoc?.roles?.bike_shipper || userDoc?.roles?.admin) && (
              <DashboardMenuItem
                selected={pathname.includes('bike-shipments')}
                menuItemClicked={menuItemClicked}
                text="Ship my Dirt Bike"
                icon={<FaBicycle width={48} height={48} />}
                href="/dashboard/bike-shipments"
              />
            )}

            {userDoc?.account_activated && (
              <>
                <div className="flex flex-col p-[1.2rem]">
                  <p className=" font-normal text-black">SELL</p>
                </div>
                <DashboardMenuItem
                  selected={pathname.includes('shop-overview')}
                  menuItemClicked={menuItemClicked}
                  text="Seller Dashboard"
                  icon={<StorefrontIcon />}
                  href="/dashboard/shop-overview"
                />

                <DashboardMenuItem
                  selected={pathname.includes('orders')}
                  menuItemClicked={menuItemClicked}
                  badgeCount={pendingOrdersCount ?? 0}
                  text="Orders"
                  icon={<BoxIcon />}
                  href="/dashboard/orders"
                />
                <DashboardMenuItem
                  selected={pathname.includes('listings')}
                  menuItemClicked={menuItemClicked}
                  text="Listings"
                  icon={<ListOutlineIcon />}
                  href="/dashboard/listings"
                />
                {userDoc.roles?.integrations && (
                  <>
                    <DashboardMenuItem
                      selected={pathname.includes('integrations')}
                      menuItemClicked={menuItemClicked}
                      text="Integrations"
                      icon={<RotateIcon />}
                      href="/dashboard/integrations"
                      badgeCount={
                        userDoc?.roles.admin ? integrationRequestsCount : 0
                      }
                    />
                    <DashboardMenuItem
                      selected={pathname.includes('billing')}
                      menuItemClicked={menuItemClicked}
                      text="Billing"
                      icon={<FaMoneyBillWave />}
                      href="/dashboard/billing"
                      badgeCount={
                        userDoc?.roles.admin ? integrationRequestsCount : 0
                      }
                    />
                  </>
                )}
                {(userDoc.roles?.reseller || userDoc.roles?.support) && (
                  <DashboardMenuItem
                    selected={pathname.includes('purchase-entry')}
                    menuItemClicked={menuItemClicked}
                    text="Purchase Entry"
                    icon={<Boxes />}
                    href="/dashboard/purchase-entry"
                  />
                )}
                <DashboardMenuItem
                  selected={pathname.includes('balance')}
                  menuItemClicked={menuItemClicked}
                  text="Balance"
                  icon={<BalancesIcon />}
                  href="/dashboard/balance"
                />
                <DashboardMenuItem
                  selected={pathname.includes('ratings')}
                  menuItemClicked={menuItemClicked}
                  text="Seller Reviews"
                  icon={<StarOutlineIcon />}
                  href="/dashboard/ratings"
                />
                <DashboardMenuItem
                  selected={pathname.includes('shipping-center')}
                  menuItemClicked={menuItemClicked}
                  text="Print a Label"
                  icon={<PrintALabelIcon />}
                  href="/dashboard/shipping-center"
                />
                {/* <DashboardMenuItem
            selected={pathname.includes('returns')}
            menuItemClicked={menuItemClicked}
            text="Returns"
            icon={<RotateIcon />}
            href="/dashboard/returns"
          /> */}
                <DashboardMenuItem
                  selected={pathname.includes('shop-setting')}
                  menuItemClicked={menuItemClicked}
                  text="Seller Settings"
                  icon={<SettingsIcon />}
                  href="/dashboard/shop-settings"
                  badgeCount={requiresStripeDetails ? 1 : 0}
                />
                <DashboardMenuItem
                  selected={pathname.includes('vacation-mode')}
                  menuItemClicked={menuItemClicked}
                  text="Vacation Mode"
                  icon={<PlaneIcon />}
                  href="/dashboard/vacation-mode"
                />
              </>
            )}

            <hr className="my-4 w-full" />
          </>
        )}

        {userDoc === null && (
          <div className="mb-[12rem] flex flex-col items-center justify-center px-8">
            <Button
              type="secondary"
              text="Sell"
              href="/login?redirectTo=sell"
              width="small"
              className="w-full !rounded-2xl"
            />
          </div>
        )}

        {!!userDoc && (
          <div className="mb-[12rem] flex flex-col items-center justify-center">
            <Button
              type="text"
              text="Sign out"
              href="/"
              leadingIcon={<LogoutIcon />}
              onClick={() => {
                logout();
                menuItemClicked();
              }}
              width="small"
            />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex h-full min-w-[26rem] flex-col gap-[0.4rem] overflow-y-scroll bg-brand-white pb-[15rem] lg:overflow-y-auto ">
        <ShopBySportMenu
          onClick={menuItemClicked}
          activeCategory={activeCategory}
          onClickCategory={onClickCateory}
        />
      </div>
    );
  }
};

export default DashboardSideMenu;
